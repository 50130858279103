<template>
  <v-container fluid class="full-height pa-0">
    <v-row class="fill-height">
      <v-col
          cols="6"
          class="pl-12 pr-12"
      >
        <div class="d-flex justify-center flex-column" style="height: 100%;">

          <div class="my-3" style=" height: 150px;">

            <img
                :src="require('../assets/logo.png')"
                class="my-3"
                style="max-height: 150px;"
            />
            <h1 class="font-weight-bold headline">
              Napravite novi korisnički račun.
            </h1>
          </div>
          <div class="inputs-container">

            <v-text-field
                dense
                outlined
                label="Ime"
                width="200"
                v-model="form.firstName"
            ></v-text-field>
            <v-text-field
                dense
                outlined
                label="Prezime"
                width="200"
                v-model="form.lastName"
            ></v-text-field>
            <v-text-field
                dense
                outlined
                v-model="form.password"
                label="Password"
                :append-icon="formOptions.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="formOptions.showPassword ? 'text' : 'password'"
                @click:append="formOptions.showPassword = !formOptions.showPassword"
            />
            <v-btn color="primary" block class="mt-4 text-capitalize letter-spacing-normal" @click="acceptInvite">
              <v-progress-circular
                  indeterminate
                  :size="24"
                  :width="2"
                  color="white"
                  v-if="acceptingInvite"
              ></v-progress-circular>
              <span class="text--white font-weight-bold" v-else
              >Napravi račun
                </span></v-btn>
          </div>
        </div>
      </v-col>

      <v-col
          cols="6" class="background">

      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
export default {
  name: 'AcceptInvite',
  methods: {
    ...mapActions({"acceptUserInvite": "user/acceptInvite", "addAlert": "global/addAlert"}),
    acceptInvite() {
      this.acceptUserInvite(this.form).then(() => {
        this.addAlert({
          "type": "success",
          "message": "You have successfully created your account"
        });
        setTimeout(() => {
          this.$router.replace('/login')
        }, 1000);
      }).catch(err => {
        let { error } = err.response.data;
        this.addAlert({
          "type": "error",
          "message": error
        })
      });
    }
  },
  mounted() {
    if (this.$route.query.token) {
      this.form.token = this.$route.query.token;
    }
  },
  computed: {
    ...mapGetters({
      "acceptingInvite": "user/acceptingInvite",
    })
  },
  data: () => ({
    form: {
      firstName: "",
      lastName: "",
      password: "",
      token: "",
    },
    formOptions: {
      showPassword: false,
    },
  }),
};
</script>

<style lang="scss" scoped>
.background {
  background: url('../assets/login_splash.jpg') center no-repeat;
  background-size: cover;
  height: 100vh;
}
.inputs-container {
  margin-top: 64px;
  width: 400px;
}
</style>
